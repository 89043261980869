import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@vaisala-rockhopper/components';
import './MethodHeader.scss';
import { isMobileOnly } from 'react-device-detect';

interface MethodHeaderProps {
  activeMethod: number;
}

const MethodHeader = ({ activeMethod }: MethodHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={`method-header ${isMobileOnly ? 'method-header--mobile' : 'method-header--desktop'}`}>
      <Heading
        className={`method-header__heading ${activeMethod > -1 ? '' : 'method-header__heading--active'} `}
        level={1}
        tabIndex={activeMethod > -1 ? -1 : 0}
      >
        {t('ui.method.chooseMethod')}
      </Heading>
    </div>
  );
};

export default MethodHeader;
